import RequestForProposal from "../components/RequestForProposal";
import React from "react";
import { SiteWrapper } from "../components/SiteWrapper";
import Seo from "../components/seo";
import { ACTIVE_PAGE } from "../components/nav/utils";

export default function GoPuffRfpPage() {
  const client = "goPuff";

  return (
    <SiteWrapper activeItem={ACTIVE_PAGE.GOPUFF}>
      <Seo title="goPuff Request For Proposal | Robotic Imaging" />
      <header className="site__header"></header>
      <main className="content">
        <RequestForProposal client={client} />
      </main>
    </SiteWrapper>
  );
}
